<template>
  <div class="d-flex justify-center">
    <div
      class="d-flex flex-column justify-center"
      style="max-width: 1000px; width: 100%"
    >
      <div class="d-flex flex-column w-100 position-relative">
        <v-img
          :src="
            profile._id == agency._id
              ? profile.bgImage
                ? profile.bgImage
                : require('@/assets/background0.png')
              : agency.bgImage
              ? agency.bgImage
              : require('@/assets/background0.png')
          "
          width="100%"
          style="flex-grow: 1"
          :aspect-ratio="$vuetify.breakpoint.smAndDown ? 414 / 180 : 1654 / 455"
          v-if="agency"
        />
        <div
          style="padding-left: 300px; background-color: #eee4ff"
          class="hidden-sm-and-down"
        >
          <v-tabs
            background-color="#eee4ff"
            @change="onSelected"
            v-model="selectedPage"
          >
            <v-tab v-for="item in menus" :key="item.title">
              {{ item.title }}
            </v-tab>
          </v-tabs>
        </div>
        <div
          style="background-color: #eee4ff"
          class="hidden-md-and-up"
          :style="
            $vuetify.breakpoint.xs && profile._id != agency._id
              ? 'margin-top: 100px;'
              : 'margin-top: 60px;'
          "
          v-if="agency"
        >
          <v-tabs
            background-color="#eee4ff"
            @change="onSelected"
            v-model="selectedPage"
            grow
          >
            <v-tab v-for="item in menus" :key="item.title">
              {{ item.title }}
            </v-tab>
          </v-tabs>
        </div>
        <div @click="onEditTitle" class="cursor-pointer">
          <agency-avatar
            v-if="agency"
            :img="agency.midThumbnail ? agency.midThumbnail : agency.image"
            :size="$vuetify.breakpoint.xs ? 140 : 180"
            :name="agency.name"
            :onImageSelected="onImageSelected"
            style="position: absolute;"
            :style="
              $vuetify.breakpoint.xs && profile._id != agency._id
                ? 'bottom: 110px; left: 50%; transform: translate(-50%,0)'
                : $vuetify.breakpoint.xs && profile._id == agency._id
                ? 'bottom: 60px; left: 50%; transform: translate(-50%,0)'
                : $vuetify.breakpoint.sm
                ? 'bottom: 60px; left: 40px;'
                : 'bottom: 0px; left: 40px;'
            "
          />
        </div>
        <v-tooltip
          bottom
          v-if="getCompletedInfo && agency && agency._id && profile._id"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="red"
              v-text="'mdi-alert'"
              v-bind="attrs"
              v-on="on"
              style="position:absolute; left: 190px; bottom: 150px;"
            />
          </template>
          <span>Incompleted profile: Missing {{ getCompletedInfo }}</span>
        </v-tooltip>
        <circle-icon-button
          text="Edit Background"
          icon="mdi-image-edit"
          style="position: absolute; top: 20px; right: 20px;"
          :onClicked="onChangeBackground"
          v-if="agency && agency._id == profile._id"
        />
        <div
          style="position: absolute;bottom: 60px;"
          :style="
            (agency && agency._id != profile._id && profileType != 'Admin'
              ? 'width: 298px;'
              : 'width: 160px;') +
              ($vuetify.breakpoint.xs
                ? 'left: 50%; transform: translate(-50%,0)'
                : 'right: 30px;')
          "
        >
          <v-btn
            @click="becameFan"
            color="#B5E539"
            :loading="becomingFan"
            v-if="agency && agency._id != profile._id && profileType != 'Admin'"
            >{{ getFanned ? "Unfollow" : "Follow" }}
            <v-icon class="ml-2" size="16">{{
              getFanned ? "mdi-account-check" : "mdi-account-plus"
            }}</v-icon></v-btn
          >
          <v-btn
            @click="goToMessage"
            class="ml-5"
            color="#B5E539"
            v-if="agency && agency._id != profile._id"
            >Message <v-icon class="ml-2" size="16">mdi-message</v-icon></v-btn
          >
        </div>
      </div>
      <router-view />
    </div>
    <agency-profile-edit-background
      :dialog="bgDialog"
      :onClose="closeBGDialog"
    />
    <agency-profile-edit-title
      :dialog="editTitleDialog"
      :onClose="onCloseEditDialog"
      v-if="editTitleDialog"
    />
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import AgencyAvatar from "@/components/agency/AgencyAvatar.vue";
import AgencyProfileEditTitle from "./edit/AgencyProfileEditTitle.vue";
import { mapActions, mapGetters } from "vuex";
import CircleIconButton from "@/components/common/CircleIconButton.vue";
import AgencyProfileEditBackground from "./edit/AgencyProfileEditBackground.vue";
export default {
  components: {
    AgencyAvatar,
    CircleIconButton,
    AgencyProfileEditBackground,
    AgencyProfileEditTitle
  },
  data() {
    return {
      errorMessage: null,
      snackbar: false,
      selectedImage: null,
      selectedPage: 0,
      menus: [
        {
          title: "Events",
          router: "agency-profile-events",
          path: "/events"
        },
        {
          title: "About",
          router: "agency-profile-about",
          path: "/needs"
        },
        {
          title: "Needs",
          router: "agency-profile-needs",
          path: "/needs"
        },
        {
          title: "Volunteers",
          router: "agency-profile-volunteers",
          path: "/volunteers"
        }
      ],
      bgDialog: false,
      becomingFan: false,
      editTitleDialog: false
    };
  },
  methods: {
    ...mapActions({
      getAgency: "agency/getAgency",
      followAgency: "agency/followAgency",
      volunteerFollowAgency: "volunteer/followAgency"
    }),
    loadData() {
      this.loading = true;
      if (this.$route.params.id) {
        this.getAgency(this.$route.params.id)
          .then(() => {
            this.loading = false;
          })
          .catch(error => {
            this.loading = false;
            if (error.response != undefined && error.response.status === 401) {
              this.tokenDialog = true;
            } else {
              console.log(error.response.data);
              this.$router.push({
                name: "404"
              });
            }
          });
      } else {
        this.$router.push({
          name: "agency-profile-details",
          params: { id: this.profile._id }
        });
      }
    },
    onImageSelected(image) {
      this.selectedImage = image;
    },
    checkSelected(item) {
      return item === this.menus[this.selectedPage];
    },
    onSelected(index) {
      if (index != undefined) {
        if (this.$route.name && this.$route.name != this.menus[index].router) {
          this.$router.push({ name: this.menus[index].router });
        }
      } else {
        setTimeout(() => {
          for (var i = 0; i < this.menus.length; i++) {
            if (this.menus[i].router == this.$route.name) {
              this.selectedPage = i;
              break;
            }
          }
        }, 300);
      }
    },
    onChangeBackground() {
      this.bgDialog = true;
    },
    closeBGDialog() {
      this.bgDialog = false;
      this.agency.bgImage = this.profile.bgImage;
    },
    goToMessage() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.$router.push({
          name: "mobile-chat-room",
          query: { _id: this.agency._id, type: "Agency" }
        });
      } else {
        this.$router.push({
          name: "chat-details",
          query: { _id: this.agency._id, type: "Agency" }
        });
      }
    },
    becameFan() {
      var fanned = this.getFanned;
      if (this.profileType == "Agency") {
        this.becomingFan = true;
        this.followAgency({ followed: !fanned, agency: this.agency._id })
          .then(() => {
            this.becomingFan = false;
            console.log(this.profile);
          })
          .catch(error => {
            this.becomingFan = false;
            console.log(error.response.data.message);
          });
      } else if (this.profileType == "Volunteer") {
        this.becomingFan = true;
        this.volunteerFollowAgency({ fanned: !fanned, agency: this.agency._id })
          .then(() => {
            this.becomingFan = false;
          })
          .catch(error => {
            this.becomingFan = false;
            console.log(error.response.data.message);
          });
      }
    },
    onEditTitle() {
      if (this.profile._id == this.$route.params.id) {
        this.editTitleDialog = true;
      }
    },
    onCloseEditDialog() {
      this.editTitleDialog = false;
    }
  },
  computed: {
    ...mapGetters({
      profile: "auth/getProfile",
      profileType: "auth/getType",
      agency: "agency/getAgency"
    }),
    getCompletedInfo() {
      var str = "";
      if (!this.agency) return "";
      if (!this.agency.image) str += "Logo";
      if (!this.agency.who_we_are)
        str ? (str += ", Who we are") : (str = "Who we are");
      if (!this.agency.what_we_do)
        str ? (str += ", What we do") : (str = "What we do");
      if (!this.agency.contact_person || !this.agency.contact_title)
        str ? (str += ", Contact Info") : (str = "Contact Info");
      if (!this.agency.phone) str ? (str += ", Phone") : (str = "Phone");
      if (!this.agency.address) str ? (str += ", Address") : (str = "Address");
      if (!this.agency.city) str ? (str += ", City") : (str = "City");
      if (!this.agency.state) str ? (str += ", State") : (str = "State");
      if (!this.agency.zip) str ? (str += ", Zip") : (str = "Zip");

      return str;
    },
    getFanned() {
      if (this.profileType == "Agency") {
        if (!this.profile.followers) return false;
        if (this.profile.followers.includes(this.agency._id)) return true;
      } else if (this.profileType == "Volunteer") {
        if (!this.profile.fanned) return false;
        if (this.profile.fanned.includes(this.agency._id)) return true;
      }
      return false;
    }
  },
  watch: {
    "$route.params.id": function() {
      this.loadData();
    },
    "$route.name": function() {
      this.selectedPage = -1;
      for (var i = 0; i < this.menus.length; i++) {
        if (this.menus[i].router == this.$route.name) {
          this.selectedPage = i;
          break;
        }
      }
    }
  },
  mounted() {
    this.selectedPage = -1;
    for (var i = 0; i < this.menus.length; i++) {
      if (this.menus[i].router == this.$route.name) {
        this.selectedPage = i;
        break;
      }
    }
    this.loadData();
  }
};
</script>
<style scoped>
@media (max-width: 480px) {
  div :deep(.v-tab) {
    min-width: 48px;
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    justify-content: center;
  }
}
</style>
